import { t } from '@lingui/macro';
import { Autocomplete } from '@mui/material';
import { TextInput } from './TextInput';
import type { ComboBoxProps } from './types';
import { useGet } from 'lib/api';

export const ComboBox = ({
  id,
  sx,
  options = [],
  label,
  placeholder,
  clearOnBlur = true,
  openText = t`Open`,
  loading = false,
  fullWidth = false,
  loadingText = t`Loading...`,
  noOptionsText = t`No options`,
  inputVariant = 'unstyled',
  freeSolo = true,
  InputProps,
  error,
  value,
  onBlur,
  onChange,
  onClose,
  onInputChange,
  open,
  api = null,
}: ComboBoxProps) => {
  // get options from an api if provided
  const { data } = useGet(api?.url || '', api?.key || '', false, { enabled: !!api });
  const selectOptions = data && api ? api.responseTransform(data) : options;
  return (
    <Autocomplete
      disablePortal
      selectOnFocus
      value={value}
      clearOnBlur={clearOnBlur}
      freeSolo={freeSolo}
      fullWidth={fullWidth}
      openText={openText}
      id={id}
      open={open}
      loading={loading}
      loadingText={loadingText}
      noOptionsText={noOptionsText}
      options={selectOptions}
      sx={sx}
      onBlur={onBlur}
      onChange={onChange}
      onClose={onClose}
      getOptionDisabled={({ disabled = false }) => disabled}
      getOptionLabel={option => {
        if (typeof option === 'string') return option;
        if (option?.handleClick) return '';
        return option?.label;
      }}
      onInputChange={onInputChange}
      renderOption={(props, option) => {
        return (
          <li {...props}>
            {option.CustomElement ? (
              <option.CustomElement
                label={option.label}
                handleClick={option.handleClick}
                {...option.customProps}
              />
            ) : (
              <span onClick={option.handleClick}>{option.label}</span>
            )}
          </li>
        );
      }}
      renderInput={params => {
        return (
          <TextInput
            {...params}
            error={error}
            color={error ? 'error' : 'primary'}
            placeholder={loading ? loadingText : placeholder}
            label={label}
            variant={inputVariant}
            InputProps={{ ...params.InputProps, ...InputProps }}
            autoFocus={Boolean(open)}
          />
        );
      }}
    />
  );
};
