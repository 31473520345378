import { colors } from './colors';

declare module '@mui/material/styles' {
  interface Palette {
    surface: {
      main: string;
      light: string;
      dark: string;
      contrast: string;
    };
  }

  interface PaletteOptions {
    surface: {
      main: string;
      light: string;
      dark: string;
      contrast: string;
    };
  }

  interface PaletteColor {
    contrast?: string;
  }

  interface SimplePaletteColorOptions {
    contrast?: string;
  }
}

// All of these colors needs to be discussed with UX/Design team.
export const palette = {
  text: {
    primary: colors.grey[900],
    primaryOnBrand: colors.white,
    secondary: colors.grey[700],
    secondaryHover: colors.grey[800],
    secondaryOnBrand: colors.brand[200],
    tertiary: colors.grey[600],
    tertiaryHover: colors.grey[700],
    tertiaryOnBrand: colors.brand[200],
    quaternary: colors.grey[500],
    quaternaryOnBrand: colors.brand[300],
    disabled: colors.grey[400],
    disabledSubtle: colors.grey[200],
    placeholder: colors.grey[400],
    placeholderSubtle: colors.grey[200],
    brandPrimary: colors.brand[700],
    brandPrimaryHover: colors.brand[800],
    brandSecondary: colors.brand[500],
    brandTertiary: colors.brand[400],
    brandTertiaryAlt: colors.brand[400],
    error: colors.error[400],
    warning: colors.warning[500],
    success: colors.success[600],
    successNeutral: colors.marineBlue[500],
    link: colors.marineBlue[500],
    linkHover: colors.marineBlue[600],
  },
  border: {
    primary: colors.grey[300],
    primarySubtle: colors.grey[200],
    primarySolid: colors.grey[700],
    primarySolidAlt: colors.grey[500],
    secondary: colors.grey[200],
    tertiary: colors.grey[100],
    white: colors.white,
    accentPrimary: colors.marineBlue[500],
    accentPrimaryHover: colors.marineBlue[600],
    disabled: colors.grey[300],
    disabledSubtle: colors.grey[200],
    brand: colors.brand[300],
    brandSolid: colors.brand[600],
    brandSolidAlt: colors.brand[600],
    brandSecondary: colors.brand[400],
    error: colors.error[300],
    errorSolid: colors.error[600],
    success: colors.success[300],
    successSolid: colors.success[600],
    warning: colors.warning[300],
    warningSolid: colors.warning[400],
  },
  fg: {
    primary: colors.grey[800],
    secondary: colors.grey[700],
    secondaryHover: colors.grey[800],
    tertiary: colors.grey[600],
    tertiaryHover: colors.grey[700],
    quarternary: colors.grey[500],
    quarternaryHover: colors.grey[600],
    quinary: colors.grey[400],
    quinaryHover: colors.grey[500],
    senary: colors.grey[300],
    white: colors.white,
    disabled: colors.grey[300],
    disabledSubtle: colors.grey[200],
    errorPrimary: colors.error[500],
    errorSecondary: colors.error[400],
    warningPrimary: colors.warning[500],
    warningSecondary: colors.warning[400],
    successPrimary: colors.success[500],
    successSecondary: colors.success[400],
    infoPrimary: colors.marineBlue[500],
    infoSecondary: colors.marineBlue[400],
    brandPrimary: colors.brand[500],
    brandPrimaryAlt: colors.brand[500],
    brandPrimaryHover: colors.brand[600],
    brandSecondary: colors.brand[400],
  },
  bg: {
    primary: colors.white,
    primaryAlt: colors.white,
    primaryHover: colors.grey[50],
    primarySolid: colors.grey[900],
    secondary: colors.beige[300],
    secondaryAlt: colors.beige[400],
    secondaryHover: colors.beige[500],
    secondarySubtle: colors.beige[300],
    secondarySolid: colors.grey[700],
    tertiary: colors.grey[100],
    brandPrimary: colors.brand[100],
    brandSubtle: colors.brand[50],
    brandPrimaryAlt: colors.brand[100],
    brandSecondary: colors.brand[200],
    brandSolid: colors.brand[500],
    brandSolidHover: colors.brand[600],
    quarternary: colors.grey[200],
    active: colors.white,
    disabled: colors.grey[200],
    disabledSubtle: colors.grey[100],
    errorPrimary: colors.error[100],
    errorSecondary: colors.error[200],
    errorSolid: colors.error[500],
    warningPrimary: colors.warning[100],
    warningSecondary: colors.warning[200],
    warningSolid: colors.warning[500],
    successPrimary: colors.success[50],
    successSecondary: colors.success[200],
    successSolid: colors.success[500],
    infoPrimary: colors.marineBlue[100],
    infoSecondary: colors.marineBlue[300],
    infoSolid: colors.marineBlue[500],
  },
  utility: colors,
  // deprecated color tokens, remove when we update the components
  primary: {
    light: colors.brand[400],
    main: colors.brand[500],
    contrast: colors.white,
  },
  error: {
    main: colors.error[400],
    dark: colors.error[500],
    light: colors.error[300],
    contrast: colors.white,
  },
  success: {
    main: colors.success[400],
    dark: colors.success[500],
    light: colors.success[300],
    contrast: colors.black,
  },
  surface: {
    main: colors.white[600],
    light: colors.white,
    dark: colors.grey[900],
    contrast: colors.black,
  },
};
